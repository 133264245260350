import { navigate } from "gatsby"
import { UPDATE_AUTH } from "../App/AuthenticationContext"

export const LOCAL_STORAGE_SESSION_KEY = 'mcsession'

export const isLoggedIn = (authState) => {
    return authState.session !== null
}

export const logout = (authDispatch) => {
    authDispatch(
        {
            type: UPDATE_AUTH,
            payload: {
                session: null,
                email: null,
            },
        }
    )
    localStorage.removeItem(LOCAL_STORAGE_SESSION_KEY)
    navigate(`/`)
}