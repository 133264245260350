import Axios from "axios";
import { getRegionalizedUrl, MANAGE_API } from "@mc/common/lib/url-utils";

export const getCustomerInfo = (authState) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(MANAGE_API) + '/get_customer_info',
        params: {
            v: 1,
            user_email: authState.email || '',
            session: authState.session || '',
        },
    })
}

export const getCustomerList = (authState) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(MANAGE_API) + '/get_customer_list',
        params: {
            v: 1,
            user_email: authState.email || '',
            session: authState.session || '',
        },
    })
}